<template>
  <div class="ma-0 pa-0">
    <v-row>
      <v-col cols="7">
        <DischargeSummaryInfo ref="DoctorDischargeSummaryInfo" />
      </v-col>
      <v-col cols="5" class="pl-10">
        <ServiceTable ref="ServiceTable" />
        <!-- <v-row justify="center" class="mt-0">
          <a @click="printMedicinePrescription" class="mr-5">
            <v-icon color="primary" class="mr-1">mdi-printer</v-icon>
            Medication
          </a>
          <a @click="printGlassPrescription" class="mr-5">
            <v-icon color="primary" class="mr-1">mdi-printer</v-icon>
            Glass Prescription
          </a>
          <a @click="printDischargeSummary" class="mr-5">
            <v-icon color="primary" class="mr-1">mdi-printer</v-icon>
            Discharge Summary
          </a>
        </v-row> -->
        <GeneralPrint 
          :childrensMenu="childrensMenu"
          :optionPrint="OptionPrint.Vertical" 
          :patientInfo="patientInfo"
          :listWantToPrint="listWantToPrint" 
          :justIcon="justIcon"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GeneralPrint from "@/components/prescription/GeneralPrint"
import DischargeSummaryInfo from "@/components/common/dischargeSummary/DischargeSummaryInfo";
import ServiceTable from "@/components/common/dischargeSummary/ServiceTable";
import { OptionPrint } from "@/plugins/constant";

export default {
  components: {
    DischargeSummaryInfo,
    ServiceTable,
    GeneralPrint,
  },
  data() {
    return {
      patientInfo: {
        episodeRecordID: this.$route.params.episodeRecordID,
        patientID: this.$route.params.patientId,
      },
      OptionPrint,
      childrensMenu: false,
      justIcon: false,
      listWantToPrint: [
        "medicine-prescription",
        "discharge-summary",
        "rimmed-glass",
      ],
    };
  },
  computed: {},
  created() {
  },
  mounted() {},
  methods: {
    // showError(message) {
    //   this.$toast.error(message);
    // },
    // showSuccess(message) {
    //   this.$toast.success(message);
    // },
    // printDischargeSummary() {
    //   let patientID = this.$route.params.patientId;
    //   let episodeRecordID = this.$route.params.episodeRecordID;
    //   let srcLinkPrint = `/patient/${patientID}/prescription/${episodeRecordID}/discharge`;
    //   window.open(srcLinkPrint, "Discharge Summary", "width=800, height=500");
    // },
    // printMedicinePrescription() {
    //   let patientID = this.$route.params.patientId;
    //   let episodeRecordID = this.$route.params.episodeRecordID;
    //   let srcLinkPrint = `/patient/${patientID}/prescription/${episodeRecordID}/medicine`;
    //   window.open(
    //     srcLinkPrint,
    //     "Medicine Prescription",
    //     "width=900, height=600"
    //   );
    // },
    // printGlassPrescription() {
    //   let patientID = this.$route.params.patientId;
    //   let episodeRecordID = this.$route.params.episodeRecordID;
    //   let srcLinkPrint = `/patient/${patientID}/prescription/${episodeRecordID}/glass`;
    //   window.open(srcLinkPrint, "Glass Prescription", "width=900, height=600");
    // },
  },
};
</script>

<style lang="scss">
.discharge-title {
  font-size: 16px;
  padding: 4px 0;
}
.none-border-top {
  border-top: none;
}
.right-btn {
  float: right;
}
</style>

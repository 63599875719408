import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'
import AppConstant from '../plugins/constant'

var httpClient = new HttpClient(ApiGateway.Hr);

const prefix = '/Brand/User';
export default {
  searchUser: (clinicID, statusID, keySearch, page = 1, limit = 10) => {
    var query = {
      clinicID,
      statusID,
      keySearch
    }

    var header = {
      page,
      limit: limit || AppConstant.DefaultPaginationLimit
    }
    return httpClient.get(`${prefix}/Search`, query, header);
  },
  searchListBrandUsers(keySearch, clinicID, page, limit) {
    var query = {
      keySearch,
      clinicID
    }
    var headers = {
      page,
      limit: limit || AppConstant.DefaultPaginationLimit
    }
    return httpClient.get(`${prefix}/Search`, query, headers);
  },
  getBrandUserByID(userID) {
    var query = {
      userID
    }
    return httpClient.get(`${prefix}/Get/{userID}`, query);
  },
  updateBrandUserByID(userID, data) {
    var query = {
      userID
    }
    return httpClient.put(`${prefix}/Update/{userID}`, query, data);
  },
  createBrandUser(data) {
    return httpClient.post(`${prefix}/Create`, null, data);
  },
  getPersonalProfile() {
    return httpClient.get(`${prefix}/Get`);
  },
  updatePersonalProfile(data) {
    return httpClient.put(`${prefix}/Update`, null, data);
  },
  getAllUserBaseOnClinic() {
    return httpClient.get(`${prefix}/Get/All`, null);
  }
}
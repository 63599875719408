<template>
  <v-row>
    <v-col cols="3">
      <v-card-subtitle class="discharge-title">
        {{ $t("examination.dischargeSummary.conclusion") }} :
      </v-card-subtitle>
    </v-col>
    <v-col cols="9">
      <span v-if="!conclusion"
        >{{ $t("examination.dischargeSummary.noConclusion") }}
      </span>
      <h4 v-else class="discharge-title" v-html="conclusion"></h4>
    </v-col>
  </v-row>
</template>

<script>
import ConclusionService from "@/services/conclusion";
import { ConclusionType } from "@/plugins/constant";

export default {
  components: {},
  data() {
    return {
      conclusion: "",
    };
  },
  mounted() {},
  async created() {
    this.conclusion = await this.renderConclusionDetail();
  },
  methods: {
    getCodePositionName(code) {
      return code == 1 ? "Mắt phải" : code == 2 ? "Mắt trái" : "Hai mắt";
    },
    async renderConclusionDetail() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      var result = await ConclusionService.getConclusionDetail(episodeRecordID);
      if (!result) return;
      var { conclusionType, conclusionSuggestion } = result;
      if (conclusionType == ConclusionType.Normal) {
        return "Bệnh nhân thực hiện theo chỉ định của đơn kính và đơn thuốc. Tái khám theo lịch đã hẹn (nếu có).";
      }
      if (conclusionType == ConclusionType.Symptoms) {
        conclusionSuggestion = conclusionSuggestion || [];
        var concludeStr = `Bệnh nhân này nghi ngờ bị `;
        var symptoms = !conclusionSuggestion.length
          ? "bệnh"
          : conclusionSuggestion
              .filter((i) => i.codeDescription)
              .map(
                (i) =>
                  `${i.codeDescription} ở ${this.getCodePositionName(
                    i.conclusionCodePosition
                  )}`
              )
              .join(" và ");
        concludeStr += symptoms;
        concludeStr += ". Xin chuyển bác sĩ theo dõi tiếp .";
        return concludeStr;
      }
      return "";
    },
  },
};
</script>
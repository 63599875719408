var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticClass:"referralPopup-custom"},[_c('v-container',{staticClass:"pa-0"},[_c('v-card-title',{staticClass:"title-center"},[_vm._v(" "+_vm._s(_vm.$t("examination.dischargeSummary.referralLetter.title"))+" ")])],1),_c('v-container',{staticClass:"ma-0 pb-2 service-information"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.clinics,"dense":"","label":_vm.$t('examination.dischargeSummary.referralLetter.clinic'),"placeholder":_vm.$t('examination.dischargeSummary.referralLetter.chooseClinic'),"outlined":""},model:{value:(_vm.clinicID),callback:function ($$v) {_vm.clinicID=$$v},expression:"clinicID"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.doctors,"dense":"","label":"Doctor","placeholder":_vm.$t('examination.dischargeSummary.referralLetter.doctor'),"outlined":""},model:{value:(_vm.doctorID),callback:function ($$v) {_vm.doctorID=$$v},expression:"doctorID"}})],1)],1)],1),_c('v-container',{staticClass:"pa-0 ma-0 fw h-4"},[_c('p',{staticClass:"fl lh-4 mr-3 row-title"},[_vm._v(" "+_vm._s(_vm.$t("examination.dischargeSummary.referralLetter.package"))+": ")]),_c('v-select',{staticClass:"fl mr-3",staticStyle:{"width":"210px"},attrs:{"items":_vm.itemTypes,"filled":"","dense":"","full-width":"","label":"Item Type","append-icon":"mdi-book-outline"},model:{value:(_vm.itemType),callback:function ($$v) {_vm.itemType=$$v},expression:"itemType"}}),_c('v-autocomplete',{staticClass:"fl mr-3",staticStyle:{"width":"230px"},attrs:{"items":_vm.items,"filled":"","dense":"","full-width":"","label":_vm.$t('common.select') +
            ' ' +
            _vm.itemTypes.find(function (i) { return i.value == _vm.itemType; }).text,"append-icon":"mdi-package-variant-closed"},model:{value:(_vm.itemID),callback:function ($$v) {_vm.itemID=$$v},expression:"itemID"}}),_c('v-btn',{staticClass:"h-4 lh-4",attrs:{"loading":_vm.isAddingMoreItem,"elevation":"2"},on:{"click":function($event){_vm.isAddingMoreItem = true;
            _vm.addIntoTreeView(_vm.itemID, _vm.itemType);}}},[_vm._v(_vm._s(_vm.$t("common.add")))])],1),_c('v-container',{staticClass:"pa-0 ma-0 fw mt-3"},[_c('p',{staticClass:"fl lh-4 row-text",staticStyle:{"width":"calc(100% - 0px)"}},[_c('v-toolbar',{staticClass:"primary",attrs:{"dense":"","dark":"","flat":""}},[_c('p',{staticClass:"fl ma-0 pl-4",style:({ width: '520px' })},[_vm._v(" "+_vm._s(_vm.$t("examination.dischargeSummary.referralLetter.examination"))+" ")]),_c('p',{staticClass:"fl ma-0 tc",staticStyle:{"width":"60px"}},[_vm._v("Clinic")])]),_c('v-skeleton-loader',{attrs:{"height":"200px","loading":_vm.isLoadingServices,"type":"table-heading, list-item, list-item, list-item"}},[(_vm.treeItems.length)?_c('v-treeview',{attrs:{"open-all":"","open-on-click":"","dense":"","return-object":"","selected-color":"primary","items":_vm.treeItems,"open":_vm.opens},on:{"update:open":function($event){_vm.opens=$event}},scopedSlots:_vm._u([{key:"label",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"content-name pa-0 ma-0"},'p',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])]),_c('v-btn',{staticStyle:{"margin-left":"40px"},attrs:{"icon":"","color":"black","width":"30","height":"30"},on:{"click":function($event){return _vm.removeItemInList(item)}}},[_c('v-icon',{attrs:{"dark":"","size":"20"}},[_vm._v("mdi-close-circle-outline ")])],1)]}},{key:"append",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"fr ma-0 h-4 lh-4 tc short-text",staticStyle:{"width":"200px"}},'p',attrs,false),on),[_vm._v(" "+_vm._s(item.address)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.address))])])]}}],null,false,163892523),model:{value:(_vm.selections),callback:function ($$v) {_vm.selections=$$v},expression:"selections"}}):_c('span',{staticClass:"no-data-text"},[_vm._v(" "+_vm._s(_vm.$t("examination.dischargeSummary.referralLetter.addTitle"))+" ")])],1)],1)]),_c('v-container',[_c('v-card-title',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(_vm.$t("examination.dischargeSummary.referralLetter.note"))+" ")]),_c('v-textarea',{staticClass:"fit-text-input",attrs:{"outlined":"","name":"Note","label":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('v-card-actions',{staticClass:"fit-button"},[_c('v-btn',{staticClass:"pl-7 pr-7",on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("common.cancel"))+" ")]),_c('v-btn',{staticClass:"px-4 py-3 white--text pl-10 pr-10",attrs:{"depressed":"","color":"#0065FF"},on:{"click":function($event){return _vm.SaveReferral()}}},[_vm._v(" "+_vm._s(_vm.$t("common.Save"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="mt-4 mb-3 pl-3">
    <v-row class="service__title">
      <v-col cols="5" class="text-left">
        <h4>{{ $t("examination.dischargeSummary.medName") }}</h4>
      </v-col>
      <v-col cols="7" class="text-center">
        <h4>{{ $t("examination.dischargeSummary.intruction") }}</h4>
      </v-col>
    </v-row>
    <v-row
      v-for="(item, idx) in items"
      :key="idx"
      class="service-content none-border-top"
    >
      <v-col cols="5" class="text-left">
        <h4>{{ item.medicineName || "N/A" }}</h4>
      </v-col>
      <v-col cols="7" class="text-center">
        <h4>{{ item.note || "N/A" }}</h4>
      </v-col>
    </v-row>
    <v-row v-if="!items.length" class="service-content">
      <v-col cols="12">
        {{ $t("examination.dischargeSummary.noMedicine") }}
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      items: [],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    loadMedications(items) {
      this.items = items;
    },
  },
};
</script>
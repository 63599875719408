<template>
  <v-row>
    <v-col cols="3">
      <v-card-subtitle class="discharge-title">
        {{ $t("examination.dischargeSummary.image") }} :
      </v-card-subtitle>
    </v-col>
    <v-col cols="9">
      <span v-if="!image">{{
        $t("examination.dischargeSummary.noImage")
      }}</span>
      <a v-else class="discharge-title" @click="openNewTab(image)"
        >{{ image }}
      </a>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  async created() {},
  methods: {
    openNewTab(link) {
      window.open(link, "_blank");
    },
  },
};
</script>
<template>
  <v-row>
    <v-col cols="3">
      <v-card-subtitle class="discharge-title">
        {{ $t("examination.dischargeSummary.glassPrescription") }} :
      </v-card-subtitle>
    </v-col>
    <v-col cols="9">
      <a @click="printGlassPrescription" class="mr-5">
        <v-icon color="primary" class="mr-1">mdi-printer</v-icon>
        {{ $t("examination.dischargeSummary.printGlass") }}
      </a>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  async created() {},
  methods: {
    printGlassPrescription() {
      let patientID = this.$route.params.patientId;
      let episodeRecordID = this.$route.params.episodeRecordID;
      let srcLinkPrint = `/patient/${patientID}/prescription/${episodeRecordID}/glass`;
      window.open(srcLinkPrint, "Glass Prescription", "width=800, height=500");
    },
  },
};
</script>
<template>
  <div class="mt-4 mb-3 pl-3">
    <v-row class="service__title">
      <v-col cols="3" class="text-left">
        <h4>{{ $t("examination.dischargeSummary.nameStaff") }}</h4>
      </v-col>
      <v-col cols="2" class="text-left">
        <h4>{{ $t("examination.dischargeSummary.position") }}</h4>
      </v-col>
      <v-col cols="7" class="text-center">
        <h4>{{ $t("examination.dischargeSummary.examination") }}</h4>
      </v-col>
    </v-row>
    <v-row
      v-for="(item, idx) in items"
      :key="idx"
      class="service-content none-border-top"
    >
      <v-col cols="3" class="text-left">
        <h4>{{ item.staffPerformName || "N/A" }}</h4>
      </v-col>
      <v-col cols="2" class="text-left">
        <h4>{{ getTypeCodeName(item.staffPerformUserTypeID) }}</h4>
      </v-col>
      <v-col cols="7" class="text-left">
        <h4>{{ item.name || "N/A" }}</h4>
      </v-col>
    </v-row>
    <v-row v-if="!items.length" class="service-content">
      <v-col cols="12">{{ $t("print.discharge.noExam") }}. </v-col>
    </v-row>
  </div>
</template>
<script>
import { UserTypeCodes } from "@/plugins/constant";
export default {
  components: {},
  data() {
    return {
      items: [],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getTypeCodeName(typeID) {
      return Object.keys(UserTypeCodes)[typeID + 1] || "Staff";
    },
    loadExaminations(items) {
      this.items = items;
    },
  },
};
</script>
<template>
  <v-row>
    <v-col cols="3">
      <v-card-subtitle class="discharge-title"
        >{{ $t("examination.dischargeSummary.followUp") }} :</v-card-subtitle
      >
    </v-col>
    <v-col cols="9">
      <span v-if="!dateFollowUp.length">{{
        $t("examination.dischargeSummary.noFollowUp")
      }}</span>
      <h4
        v-else
        class="discharge-title"
        v-for="(item, index) in dateFollowUp"
        :key="index"
        v-html="formatDate(item.timeStart)"
      ></h4>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  props: {
    dateFollowUp: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  async created() {},
  methods: {
    jsUcfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatDate(date) {
      if (!date) return "";
      return `<h4>${this.jsUcfirst(
        moment(date).lang("vi").format("dddd")
      )}, Ngày ${moment(date).format("DD/MM/YYYY")} lúc ${moment(date).format(
        "HH:mm"
      )}</h4>`;
    },
  },
};
</script>
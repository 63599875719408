<template>
  <div class="discharge-summary-info">
    <div>
      <h3 class="mb-3 mt-2">
        {{ $t("examination.dischargeSummary.optometristSummary") }}:
      </h3>
      <ChiefComplain :chiefComplain="chiefComplain" />
      <ImageLink :image="image" />
      <Conclusion />
      <FollowUp :dateFollowUp="dateFollowUp" />
      <PrintGlass />
      <v-row>
        <v-col cols="12">
          <ExaminationTable ref="OptometristExaminationTable" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <MedicationTable ref="OptometristMedication" />
        </v-col>
      </v-row>
    </div>
    <div v-show="isDoctorRole">
      <h3 class="mb-3 mt-6">
        {{ $t("examination.dischargeSummary.doctorSummary") }} :
      </h3>
      <ChiefComplain :chiefComplain="chiefComplain" />
      <ImageLink :image="image" />
      <Diagnosis />
      <AxPx />
      <FollowUp :dateFollowUp="dateFollowUp" />
      <v-row>
        <v-col cols="12">
          <ExaminationTable ref="DoctorExaminationTable" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <MedicationTable ref="DoctorMedication" />
        </v-col>
      </v-row>
    </div>
    <DischargeMode ref="DischargeMode" />
  </div>
</template>

<script>
import MedicationTable from "./components/MedicationTable";
import ExaminationTable from "./components/ExaminationTable";
import ChiefComplain from "./components/ChiefComplain";
import Conclusion from "./components/Conclusion";
import ImageLink from "./components/ImageLink";
import FollowUp from "./components/FollowUp";
import PrintGlass from "./components/PrintGlass";
import DischargeMode from "./components/DischargeMode";
import Diagnosis from "./components/Diagnosis";
import AxPx from "./components/AxPx";
import WorkListService from "@/services/work-list";
import moment from "moment";
import BookingService from "@/services/booking";
import DataPointService from "@/services/dataPoint";
import MedicationService from "@/services/medication";
import EpisodeService from "@/services/episode";

import { UserTypeCodes } from "@/plugins/constant";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AxPx,
    FollowUp,
    Diagnosis,
    ImageLink,
    Conclusion,
    PrintGlass,
    DischargeMode,
    ChiefComplain,
    MedicationTable,
    ExaminationTable,
  },
  data() {
    return {
      chiefComplain: "",
      dateFollowUp: [],
      image: "Link to image",
      page: 1,
      itemsPerPage: 10000,
      episodeRecordID: this.$route.params.episodeRecordID,
      searchOptions: {
        clinicID: this.$store.getters.userData.clinicID,
        keySearch: "",
        statusID: 1,
      },
    };
  },
  computed: {
    ...mapState("FollowUp", ["bookingOfPatient"]),
    ...mapActions("FollowUp", ["getBookingPatient"]),
    isDoctorRole() {
      return [
        UserTypeCodes.Root,
        UserTypeCodes.Admin,
        UserTypeCodes.Doctor,
      ].includes(this.$store.getters.userData.userTypeID);
    },
  },
  mounted() {},
  async created() {
    this.getEpisodeInfo();
    this.renderChiefComplainAndListExams();
    this.renderFollowUp();
    this.renderImageLink();
    this.renderMedications();
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    async getEpisodeInfo() {
      const result = await EpisodeService.getEpisodeDetailByEpisodeRecord(
        this.episodeRecordID
      );
      if (!result || result.error) {
        this.showError(
          "Can not get episode information. Please try again later."
        );
        return;
      }
      this.$refs.DischargeMode.episodeID = result.id;
      if (result.statusID == 2) {
        this.$refs.DischargeMode.isFinishExamination = true;
        this.$refs.DischargeMode.renderDischargeDetail();
      }
    },
    async renderImageLink() {
      const apiResult = await DataPointService.searchDataPoint(
        this.$route.params.episodeRecordID,
        "IMGL",
        1,
        100
      );
      if (!apiResult || apiResult.error) {
        this.showError(
          "Cannot get image link at the moment! Please try again later."
        );
        return;
      }
      this.image = apiResult.items[0] && apiResult.items[0].value;
    },
    async renderFollowUp() {
      var apiResult = await BookingService.getBookingByPatient(
        this.$route.params.patientId,
        moment().format("YYYY-MM-DD"),
        ""
      );
      if (!apiResult || apiResult.error) {
        this.showError(
          "Cannot get follow up at the moment! Please try again later."
        );
        return;
      }
      this.dateFollowUp = apiResult.items;
    },
    async renderMedications() {
      const episodeRecordID = this.$route.params.episodeRecordID;
      var result = await MedicationService.search(
        episodeRecordID,
        this.searchOptions.keySearch,
        this.page,
        this.itemsPerPage
      );
      if (!result || result.error) {
        this.showError("Cannot get list medicines. Please try again later!");
        return;
      }
      // load medication optometrists
      this.$refs.OptometristMedication.loadMedications(
        result.items.filter(
          (i) => i.userPrescribeRoleID == UserTypeCodes.Optomologist
        )
      );
      // load medication doctors
      this.$refs.DoctorMedication.loadMedications(
        result.items.filter(
          (i) => i.userPrescribeRoleID != UserTypeCodes.Optomologist
        )
      );
    },
    // function to render list service items queue
    async renderChiefComplainAndListExams() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      if (!episodeRecordID) return;
      var result = await WorkListService.GetByEpisodeRecordID(episodeRecordID);
      if (result.error) {
        this.showError("Cannot get work list info! Please try again later!");
        return;
      }
      var { orders, admissionInfo } = result || {};
      var items = JSON.parse(JSON.stringify(orders || []));
      // map path for each order
      items.forEach((item) => {
        item.path = `examination?orderID=${item.orderID}&formID=${item.formID}&targetID=${item.targetID}`;
      });
      // find order performed by optomologist
      this.$refs.OptometristExaminationTable.loadExaminations(
        items.filter((i) =>
          i.permissionUserTypeIDs.includes(UserTypeCodes.Optomologist)
        )
      );
      // find order performed by doctor
      this.$refs.DoctorExaminationTable.loadExaminations(
        items.filter(
          (i) => !i.permissionUserTypeIDs.includes(UserTypeCodes.Optomologist)
        )
      );
      if (admissionInfo.chiefComplaints) {
        this.chiefComplain = admissionInfo.chiefComplaints;
      }
    },
  },
};
</script>
<style lang="scss">
.discharge-mode-center {
  margin-top: 10px;
}
.discharge-summary-info {
  h4 {
    font-weight: 500;
  }
}
.discharge-summary-info {
  > div {
    > .row {
      margin: -10px;
      padding: 0px;
      .col {
        padding: 10px;
        p {
          padding: 0px;
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
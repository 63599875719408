<template>
  <div class="service-table">
    <v-row class="service__title">
      <v-col cols="7" class="text-center">
        <h4>{{ $t("examination.dischargeSummary.service") }}</h4>
      </v-col>
      <v-col cols="5" class="text-center">
        <h4>{{ $t("examination.dischargeSummary.price") }}</h4></v-col
      >
    </v-row>
    <v-row v-if="!items.length" class="service-content">
      <v-col cols="12">
        {{ $t("examination.dischargeSummary.noSercice") }}
      </v-col>
    </v-row>
    <v-row
      v-for="(item, idx) in items"
      :key="idx"
      class="service-content none-border-top"
    >
      <v-col cols="7" class="text-left">
        <h4>{{ item.itemName }}</h4>
      </v-col>
      <v-col cols="5" class="text-right">
        <h4>{{ formatter(item.currentPrice) }}</h4>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { convertPriceString } from "@/plugins/helper";
import PaymentService from "@/services/payment";

export default {
  components: {},
  data() {
    return {
      items: [],
    };
  },
  computed: {},
  mounted() {
    this.renderPaymentDetail();
  },
  methods: {
    formatter: (value) => {
      return convertPriceString(value);
    },
    async renderPaymentDetail() {
      var result = await PaymentService.getPaymentByEpisodeRecordID(
        this.$route.params.episodeRecordID
      );
      if (result.error) {
        this.showError("Cannot get payment summary. Please try again later!");
        return;
      }
      this.items = result.items;
    },
  },
};
</script>

<style lang="scss">
.service-table {
  margin: 0px 0 40px 0px;
  width: 100%;
  display: inline-block;
}
.service__title {
  background-color: #03214d;
  color: #ffffff;
  font-size: 14px;
}
.service-content {
  border: 1px solid rgba(0, 0, 0, 0.6);
  margin-top: -1px;
}
</style>
<template>
  <v-form ref="dischargeSummaryForm">
    <v-row class="mt-3">
      <v-col cols="3">
        <v-card-subtitle class="discharge-title discharge-mode-center">
          {{ $t("examination.dischargeSummary.dischargeMode") }} :
        </v-card-subtitle>
      </v-col>
      <v-col cols="9">
        <v-row>
          <v-col cols="4">
            <v-select
              :items="choiceDischargeMode"
              :disabled="isFinishExamination"
              v-model="dischargeModeID"
              label="Choice"
              outlined
              :rules="requiredRules"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-card-subtitle class="discharge-title discharge-mode-center"
              >{{ $t("examination.dischargeSummary.patientSatisfaction") }} :
            </v-card-subtitle>
          </v-col>
          <v-col cols="4">
            <v-select
              :items="choicePatientSatisfaction"
              :disabled="isFinishExamination"
              v-model="patientSatisfactionID"
              label="Choice"
              outlined
              :rules="requiredRules"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-show="dischargeModeID == 2">
      <v-row justify="center" class="mt-0">
        <v-col cols="6"
          ><h3>{{ $t("examination.dischargeSummary.referral") }}</h3></v-col
        >
        <v-col cols="6" class="text-right"
          ><a
            v-if="!referralID && !isFinishExamination"
            type="button"
            @click="handleCreateReferralClick"
          >
            {{ $t("common.add") }}
          </a>
          <a
            v-if="referralID && !isFinishExamination"
            type="button"
            @click="handleUpdateReferralClick"
            >{{ $t("common.edit") }}
          </a>
        </v-col>
      </v-row>
      <div class="referral-table">
        <v-row class="service__title">
          <v-col cols="10" class="text-left">
            <h4>Procedure / Assessment</h4>
          </v-col>
          <v-col cols="2" class="text-center" v-if="!isFinishExamination">
            <h4>{{ $t("examination.dischargeSummary.action") }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="!items.length">
          <v-col cols="12" class="no-data-text"
            >{{ $t("examination.dischargeSummary.noReferral") }}
          </v-col>
        </v-row>
        <v-row
          class="referral-content"
          v-else
          v-for="(item, index) in items"
          :key="index"
        >
          <v-col cols="11" v-if="item.axPxName"
            ><h4>Procedure/Assessment : {{ item.axPxName }}</h4>
          </v-col>
          <v-col cols="11" v-else
            ><h4>
              {{ $t("examination.dischargeSummary.noSercice") }} :
              {{ item.serviceName }}
            </h4>
          </v-col>
          <v-col cols="1" class="left-icon" v-if="!isFinishExamination">
            <v-btn
              @click="deleteAxPxInReferral(item.id)"
              class="mx-2 elevation-0 pa-0"
              style="height: 25px; width: 25px"
              fab
              small
            >
              <v-icon dark> mdi-delete </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-bottom-navigation
      grow
      height="50"
      absolute
      class="elevation-0 exam-bottom-controller"
      hide-on-scroll
    >
      <v-btn
        class="pl-10 pr-10 white--text"
        color="#0065FF"
        @click="finishEpisode"
        v-bind:disabled="isFinishExamination === true ? true : false"
      >
        {{ $t("common.finish") }}
      </v-btn>
    </v-bottom-navigation>
    <DialogConfirm ref="DialogConfirm" />
    <ReferralPopup
      @onHandleReRenderReferral="renderReferral"
      ref="ReferralPopup"
    />
  </v-form>
</template>

<script>
import moment from "moment";
import { requiredRules } from "@/plugins/rules";
import i18n from "@/plugins/i18n";
//Service
import DischargeService from "@/services/discharge";
import EpisodeService from "@/services/episode";
import ReferralService from "@/services/referral";

//Component
import DialogConfirm from "@/components/DialogConfirm";
import ReferralPopup from "@/components/common/dischargeSummary/components/Form/ReferralPopup";

export default {
  components: {
    DialogConfirm,
    ReferralPopup,
  },
  data() {
    return {
      requiredRules,
      items: [],
      referralData: null,
      referralID: null,
      episodeID: null,
      dateCurrent: moment().format("MMM DD"),
      choiceDischargeMode: [],
      choicePatientSatisfaction: [],
      dischargeModeID: 1,
      patientSatisfactionID: 1,
      isFinishExamination: false,
    };
  },
  mounted() {},
  async created() {
    this.renderDischargeModeOption();
    this.renderPatientSatisfactionOption();
    this.renderReferral();
  },
  methods: {
    showError(message) {
      // this.$toast.error(message);
      console.error(message);
    },
    showSuccess(message) {
      this.$toast.success(message);
    },
    handleCreateReferralClick() {
      this.$refs.ReferralPopup.openCreate();
    },
    async handleUpdateReferralClick() {
      this.$refs.ReferralPopup.openUpdate(this.referralData);
    },
    async renderDischargeModeOption() {
      var result = await DischargeService.searchDischargeMode("", 1, 100);
      if (result.error) {
        this.showError("Cannot get discharge mode. Please try again later!");
        return;
      }
      this.choiceDischargeMode = result.items.map((i) => ({
        value: i.dischargeModeID,
        text: i.codeDescription,
      }));
      this.dischargeModeID =
        this.choiceDischargeMode[0] && this.choiceDischargeMode[0].value;
    },
    async renderPatientSatisfactionOption() {
      var result = await DischargeService.searchPatientSatisfaction("", 1, 100);
      if (!result || result.error) {
        this.showError(
          "Cannot get patient satisfaction. Please try again later!"
        );
        return;
      }
      this.choicePatientSatisfaction = result.items.map((i) => ({
        value: i.patientSatisfactionID,
        text: i.codeDescription,
      }));
      this.patientSatisfactionID =
        this.choicePatientSatisfaction[0] &&
        this.choicePatientSatisfaction[0].value;
    },
    async renderDischargeDetail() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      var result = await DischargeService.getDischargeDetail(episodeRecordID);
      if (!result) {
        this.$toast.warn("Cannot find discharge mode detail!");
        this.dischargeModeID =
          this.choiceDischargeMode[0] && this.choiceDischargeMode[0].value;
        this.patientSatisfactionID =
          this.choicePatientSatisfaction[0] &&
          this.choicePatientSatisfaction[0].value;
        return;
      }
      if (result.error) {
        this.showError("Cannot get discharge detail. Please try again later!");
        return;
      }
      this.dischargeModeID = result.dischargeModeID;
      this.patientSatisfactionID = result.patientSatisfactionID;
    },
    async finishEpisode() {
      if (!(await this.$refs.DialogConfirm.confirm())) return;
      if (!this.$refs.dischargeSummaryForm.validate()) {
        this.$toast.error(
          "One or more validation rules are not accepted. Please try again"
        );
        return;
      }
      var episodeRecordID = this.$route.params.episodeRecordID;
      var result = await EpisodeService.updateFinishStatusEpisode(
        episodeRecordID
      );
      if (!result || result.error) {
        this.$toast.error("Cannot finish examination. Please try again later!");
        return;
      }
      var data = {
        patientID: this.$route.params.patientId,
        dischargeTime: moment().toDate(),
        dischargeModeID: this.dischargeModeID,
        patientSatisfactionID: this.patientSatisfactionID,
      };
      var creataion = await DischargeService.createDischarge(
        episodeRecordID,
        data
      );
      if (creataion.error) {
        this.$toast.error("Cannot create a discharge detail !");
        return;
      }
      this.showSuccess(i18n.t("message.dischargeMode.finishExamination"));
      this.sockets.common.invoke("CompleteEpisode", this.episodeID);
      // get again episode status
      this.isFinishExamination = true;
    },
    async renderReferral() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      var result = await ReferralService.getReferral(episodeRecordID);
      this.items = result.referralItems ?? [];
      this.referralID = result.referralID ?? null;
      this.$refs.ReferralPopup.resetForm(this.referralID);
    },
    async deleteAxPxInReferral(id) {
      if (!(await this.$refs.DialogConfirm.confirm())) return;
      var result = await ReferralService.deleteAxPxReferral(id);
      if (result.error) {
        this.showError(
          "Cannot delete AxPx in this referral. Please try again later!"
        );
        return;
      }
      this.showSuccess(i18n.t("message.dischargeMode.deleteAxPx"));
      await this.renderReferral();
    },
  },
};
</script>
<style  lang="scss">
.no-data-text {
  width: 100%;
  text-align: center;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.6);
}
.left-icon {
  display: flex;
  justify-content: center;
  padding: 11px;
}
.referral-table {
  margin: 20px 0 100px 0px;
  width: 100%;
  display: inline-block;
  padding-left: 12px;
}
.referral-content {
  border: 1px solid rgba(0, 0, 0, 0.6);
  margin-top: -1px;
  border-top: none;
}
.exam-bottom-controller {
  background-color: #f5f5f5 !important;
  button {
    background-color: #0277bd !important;
    .v-btn__content {
      color: white !important;
      font-size: 120%;
      text-transform: uppercase;
    }
  }
  button:disabled {
    background-color: #bdbdbd !important;
    .v-btn__content {
      color: #424242 !important;
    }
  }
}
.v-item-group.v-bottom-navigation {
  display: flex;
  width: calc(100% - 16px) !important;
  bottom: 15px;
}
</style>
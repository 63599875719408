<template>
  <v-row>
    <v-col cols="3">
      <v-card-subtitle class="discharge-title">
       {{ $t("examination.dischargeSummary.chiefComplain") }} :
      </v-card-subtitle>
    </v-col>
    <v-col cols="9">
      <span v-if="!chiefComplain">{{ $t("examination.dischargeSummary.noComplain") }}</span>
      <h4 v-else class="discharge-title" v-html="chiefComplain"></h4>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    chiefComplain: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  async created() {},
  methods: {},
};
</script>
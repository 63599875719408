<template>
  <v-dialog v-model="showDialog" max-width="700px">
    <v-card class="referralPopup-custom">
      <v-container class="pa-0">
        <v-card-title class="title-center">
          {{ $t("examination.dischargeSummary.referralLetter.title") }}
        </v-card-title></v-container
      >

      <v-container class="ma-0 pb-2 service-information">
        <v-container>
          <v-row>
            <v-col cols="6"
              ><v-select
                v-model="clinicID"
                :items="clinics"
                dense
                :label="
                  $t('examination.dischargeSummary.referralLetter.clinic')
                "
                :placeholder="
                  $t('examination.dischargeSummary.referralLetter.chooseClinic')
                "
                outlined
              ></v-select
            ></v-col>
            <v-col cols="6"
              ><v-select
                v-model="doctorID"
                :items="doctors"
                dense
                label="Doctor"
                :placeholder="
                  $t('examination.dischargeSummary.referralLetter.doctor')
                "
                outlined
              ></v-select
            ></v-col>
          </v-row>
        </v-container>
        <v-container class="pa-0 ma-0 fw h-4">
          <p class="fl lh-4 mr-3 row-title">
            {{ $t("examination.dischargeSummary.referralLetter.package") }}:
          </p>
          <v-select
            v-model="itemType"
            :items="itemTypes"
            filled
            dense
            full-width
            label="Item Type"
            append-icon="mdi-book-outline"
            class="fl mr-3"
            style="width: 210px"
          ></v-select>
          <v-autocomplete
            v-model="itemID"
            :items="items"
            filled
            dense
            full-width
            :label="
              $t('common.select') +
              ' ' +
              itemTypes.find((i) => i.value == itemType).text
            "
            append-icon="mdi-package-variant-closed"
            class="fl mr-3"
            style="width: 230px"
          ></v-autocomplete>
          <v-btn
            :loading="isAddingMoreItem"
            @click="
              isAddingMoreItem = true;
              addIntoTreeView(itemID, itemType);
            "
            elevation="2"
            class="h-4 lh-4"
            >{{ $t("common.add") }}</v-btn
          >
        </v-container>
        <v-container class="pa-0 ma-0 fw mt-3">
          <p class="fl lh-4 row-text" style="width: calc(100% - 0px)">
            <v-toolbar dense dark flat class="primary">
              <p class="fl ma-0 pl-4" :style="{ width: '520px' }">
                {{
                  $t("examination.dischargeSummary.referralLetter.examination")
                }}
              </p>
              <p class="fl ma-0 tc" style="width: 60px">Clinic</p>

              <!-- <p class="fl ma-0 tc text-right" style="width: 200px">Clinic</p> -->
            </v-toolbar>
            <v-skeleton-loader
              height="200px"
              :loading="isLoadingServices"
              type="table-heading, list-item, list-item, list-item"
            >
              <v-treeview
                v-if="treeItems.length"
                open-all
                open-on-click
                dense
                return-object
                selected-color="primary"
                v-model="selections"
                :items="treeItems"
                :open.sync="opens"
              >
                <template v-slot:label="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <p
                        class="content-name pa-0 ma-0"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ item.name }}
                      </p>
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>
                  <v-btn
                    icon
                    color="black"
                    width="30"
                    height="30"
                    style="margin-left: 40px"
                    @click="removeItemInList(item)"
                  >
                    <v-icon dark size="20">mdi-close-circle-outline </v-icon>
                  </v-btn>
                </template>
                <template v-slot:append="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <p
                        class="fr ma-0 h-4 lh-4 tc short-text"
                        style="width: 200px"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ item.address }}
                      </p>
                    </template>
                    <span>{{ item.address }}</span>
                  </v-tooltip>
                </template>
              </v-treeview>
              <span class="no-data-text" v-else>
                {{ $t("examination.dischargeSummary.referralLetter.addTitle") }}
              </span>
            </v-skeleton-loader>
          </p>
        </v-container>
        <v-container>
          <v-card-title class="ma-0">
            {{ $t("examination.dischargeSummary.referralLetter.note") }}
          </v-card-title>
          <v-textarea
            v-model="description"
            outlined
            name="Note"
            label=""
            class="fit-text-input"
          ></v-textarea>
        </v-container>
      </v-container>

      <v-card-actions class="fit-button">
        <v-btn @click="showDialog = false" class="pl-7 pr-7">
          {{ $t("common.cancel") }}
        </v-btn>
        <v-btn
          depressed
          color="#0065FF"
          class="px-4 py-3 white--text pl-10 pr-10"
          @click="SaveReferral()"
        >
          {{ $t("common.Save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { AuditType } from "@/plugins/constant";
import { convertPriceString, awaitAll, makeUuid } from "@/plugins/helper";
import i18n from "@/plugins/i18n";
//Service
import AxPxService from "@/services/axpx";
import BrandService from "@/services/brand";
import UserService from "@/services/user";
import ReferralService from "@/services/referral";
import Service from "@/services/service";

export default {
  components: {},
  watch: {
    // When itemType change
    // Render list OrderItem Options
    itemType: {
      handler() {
        this.renderListOrderItemOptions();
      },
    },
    // When selections of OrderItem change
    // compare to get the changed element
    selections: {
      handler(newVal, oldVal) {
        // If added then nothing happens
        if (newVal.length >= oldVal.length) return;
        // If removed, find the removed item
        var removedItems = oldVal.filter(
          ({ id: id1 }) => !newVal.some(({ id: id2 }) => id2 === id1)
        );
        this.handleRemovedOrderItems(removedItems);
      },
    },
    clinicID: function (id) {
      if (id != null) {
        //handle re-render doctor when we had clinicID
        this.clinicID = id;
        this.renderListDoctorOptions();
      } else {
        this.clinicID = null;
        this.doctors = [];
      }
      this.renderListOrderItemOptions();
    },
    // Update description text
    // Reload all prices
    // Re-calculate total prices
    promotionID: {},
  },
  async created() {
    this.renderListClinicOptions();
    this.renderListOrderItemOptions();
  },
  data: () => ({
    showDialog: false,
    clinics: [],
    doctors: [],
    description: "",
    clinicID: null,
    doctorID: null,
    referralID: null,
    // always allow EMR to select Free
    allowFreeSelection: true,
    // House all promotions
    promotionID: null,
    isLoadingServices: false,
    isAddingMoreItem: false,
    promotionDescriptionText: "",
    items: [],
    itemID: null,
    itemType: AuditType.Service,
    itemTypes: [
      { value: 1, text: "Assessment" },
      { value: 2, text: "Procedure" },
      { value: 3, text: "Service" },
    ],
    treeItems: [],
    selections: [],
    opens: [],
  }),
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    showSuccess(message) {
      this.$toast.success(message);
    },
    async openCreate() {
      this.showDialog = true;
    },
    async openUpdate() {
      this.showDialog = true;
      this.treeItems = [];
      await this.handleRenderReferralDetail();
    },
    resetForm(referralID) {
      this.treeItems = [];
      this.selections = [];
      this.referralID = referralID;
    },
    // Function that add an OrderItem into TreeView
    // What it does:
    // 1. Get list details of one single item
    // 2. Convert to treeview item
    // 3. Add into TreeView
    // 4. Add into selections
    async addIntoTreeView(targetID, typeID) {
      var items = await this.getOrderItemDetails(
        targetID,
        typeID,
        this.clinicID
      );
      items = items.map((i) => this.convertIntoTreeItem(i));
      // validate if item is violated
      if (
        // If any item existed that
        items.find(
          (i) =>
            // is service but with no price or...
            (i.type == AuditType.Service && !i.priceValue) ||
            // is not service but with no room and doctor
            (i.type != AuditType.Service && (!i.roomID || !i.doctorID))
        )
      ) {
        this.showError(
          `This item cannot be addded in this clinic! Please select another one`
        );
        this.isLoadingServices = false;
        this.isAddingMoreItem = false;
        return;
      }
      this.isAddingMoreItem = false;
      // find service item
      var service = items.find((i) => i.type == AuditType.Service);

      // filter axpx items
      var axpxs = items.filter((i) => i.type != AuditType.Service);
      // add serviceID into each axpx item
      if (service && axpxs.length) {
        axpxs.forEach((i) => (i.serviceID = service.itemID));
      }
      // map selections => only add axpxs
      // because service is just a group
      // real items are axpxs
      this.selections = this.selections.concat(items);
      // this.selections = this.selections.concat(service);
      // map treeItems
      if (service) {
        service.children = axpxs;
        service.isParent = true;
        service.serviceID = service.itemID;
        this.treeItems.push(service);
      } else {
        axpxs.forEach((i) => (i.isParent = true));
        this.treeItems = this.treeItems.concat(axpxs);
      }
      // map open items
      this.opens = this.treeItems;
      this.$emit("onTreeItemsChanged", this.treeItems);
    },
    // Function that convert OrderItem into TreeItem
    convertIntoTreeItem(orderItem) {
      // transform doctors
      var room = (orderItem.rooms && orderItem.rooms[0]) || {};
      var { id, type, name, price, address } = orderItem;
      var itemID = id;
      id = `${makeUuid(5)}`;
      return {
        id,
        itemID,
        type,
        name,
        typeName: this.itemTypes.find((i) => i.value == type).text || "",
        priceValue: price,
        address: address,
        price: convertPriceString(price),
        room: room.roomName || "",
        roomID: room.roomID || "",
        doctor: room.doctorName || "",
        doctorID: room.doctorID || "",
        children: [],
      };
    },
    // Function that get all details of a single OrderItem
    // If OrderItem is Ax, Px => return [] with single Detail
    // If OrderItem is Service => return [] of each Details
    async getOrderItemDetails(targetID, typeID) {
      this.isLoadingServices = true;
      if (!targetID || !typeID) return;
      // Get list all item order details
      var itemOrders = [];
      itemOrders.push({
        id: targetID,
        typeID,
      });
      // 3. Promise all to get all item orders
      var promises = [];
      itemOrders.forEach((item) => {
        promises.push(this.getOrderItemDetail(item.id, item.typeID));
      });
      var result = await awaitAll(promises).catch(() => {
        this.showError(
          "An error occured while get list item order details. Please try again later"
        );
      });

      this.isLoadingServices = false;
      // only filter item detail = service
      // if not, filter item that have rooms != []
      return result.filter(
        (i) => i.type == AuditType.Service || i.rooms.length
      );
    },
    // for each OrderItem
    async getOrderItemDetail(targetID, typeID) {
      // const result = await AxPxService.getOrderItemDetail(targetID, typeID);
      const result = await AxPxService.getReferralOrderItemDetail(
        targetID,
        typeID,
        this.clinicID
      );
      if (result.error) {
        this.showError(
          "Can not get item order detail. Please try again later."
        );
        return;
      }
      return result;
    },
    // Function that render all OrderItem options
    async renderListOrderItemOptions() {
      let result;
      if (this.clinicID != null) {
        if (this.itemType == 3) {
          result = await Service.searchAvailableByClicnicID(this.clinicID);
        } else {
          result = await AxPxService.searchAvailable(
            this.clinicID,
            "",
            "",
            this.itemType,
            1,
            1,
            1000
          );
        }
      }
      if (this.clinicID == null) {
        if (this.itemType == 3) {
          result = await Service.search("", "", 1, 1000);
          result.items.map((i) => ({
            ...i,
            type: 3,
          }));
        } else {
          result = await AxPxService.search("", "", this.itemType, 1, 1, 1000);
        }
      }

      if (result.error) {
        this.showError("Can not get list services. Please try again later.");
        return;
      }
      this.items = result.items.map((i) => ({
        ...i,
        value: i.id,
        text: i.description,
      }));
      // auto map value đầu tiên
      this.itemID = this.items[0] && this.items[0].value;
    },
    async removeItemFromTreeViews(items) {
      // Get first item from list
      var item = items && items[0];
      if (!item) return;
      // find parent serviceID
      var isParent = item.isParent;
      // if it is a parent item
      if (isParent) {
        // then we just remove it from treeview
        this.treeItems = this.treeItems.filter((i) => i.id != item.id);
        // then finish the whole function
        return;
      }
      // if it has serviceID => it is not parent item
      // find the parentItem
      var parentItem = this.treeItems.find(
        (i) => i.children && i.children.find((c) => c.id == item.id)
      );
      if (!parentItem) return;
      // check if we are removing all of its children
      if (parentItem.children && parentItem.children.length == items.length) {
        // then we just remove the parent item from treeview
        this.treeItems = this.treeItems.filter((i) => i.id != parentItem.id);
        // then finish the whole function
        return;
      }
      // if not then we are only removing one item from the parent item
      // find the remainging items
      var remainItems = parentItem.children.filter((c) => c.id != item.id);
      // remove the whole parent item as a group
      this.treeItems = this.treeItems.filter((i) => i.id != parentItem.id);
      // then foreach remain items
      // foreach serviceItem
      for (var serviceItem of remainItems) {
        // add serviceItem into tree view as global item
        await this.addIntoTreeView(serviceItem.itemID, serviceItem.type);
      }
    },
    removeItemInList(item) {
      var removedItems = this.selections.filter((i) => i.id == item.id);
      console.log(removedItems);
      this.handleRemovedOrderItems(removedItems);
    },
    // Function that handle removed items from selections
    async handleRemovedOrderItems(items) {
      await this.removeItemFromTreeViews(items);
      this.$emit("onTreeItemsChanged", this.treeItems);
    },
    async renderListClinicOptions() {
      this.clinics = [];
      var result = await BrandService.searchBrandClinics(1, 100);
      this.clinics = result.items.map((i) => ({
        text: i.clinicName,
        value: i.clinicID,
      }));
      this.clinics.push({
        text: "Not specified",
        value: null,
      });
      // auto map value đầu tiên
      this.clinicID = this.clinics[0] && this.clinics[0].value;
    },
    async renderListDoctorOptions() {
      this.doctors = [];
      var result = await UserService.searchListBrandUsers(
        "",
        this.clinicID,
        1,
        100
      );
      this.doctors = result.items
        .filter((i) => i.userTypeName == "Doctor")
        .map((i) => ({
          text: i.fullName,
          value: i.userID,
        }));
      this.doctors.push({
        text: "Not specified",
        value: null,
      });
      this.doctorID = this.doctors[0] && this.doctors[0].value;
    },

    async handleRenderReferralDetail() {
      var itemsReferral = [];
      var episodeRecordID = this.$route.params.episodeRecordID;
      var result = await ReferralService.getReferralDetail(episodeRecordID);
      if (!result || result.error) {
        this.showError("Cannot get referral detail. Please try again later!");
        return;
      }
      //Map referral data into option
      this.clinicID = result.clinicID;
      this.doctorID = result.doctorID;
      screenTop;
      this.description = result.codeDescription;
      this.referralID = result.referralID;

      itemsReferral = result.referralItems.map((i) => ({
        ...i,
        idForm: i.axPxID ?? i.serviceID,
      }));

      itemsReferral.forEach((item) => {
        this.addIntoTreeView(item.idForm, item.typeID);
      });
    },
    async SaveReferral() {
      var result;
      var episodeRecordID = this.$route.params.episodeRecordID;
      var bodyData = {
        codeDescription: this.description,
        clinicID: this.clinicID,
        doctorID: this.doctorID,
        referralItems: [],
      };

      var itemsReferral = this.treeItems.map((i) => ({
        ServiceID: i.type == 3 ? i.itemID : null,
        AxPxID: i.type == 1 || i.type == 2 ? i.itemID : null,
      }));

      bodyData.referralItems = itemsReferral;

      if (this.referralID != null) {
        result = await ReferralService.updateReferral(
          this.referralID,
          bodyData
        );
      } else {
        result = await ReferralService.createReferral(
          episodeRecordID,
          bodyData
        );
      }
      if (!result || result.error) {
        this.showError(
          "Cannot save referral at the moment! Please try again later."
        );
        return;
      }
      this.showSuccess(i18n.t("message.referralPopUp"));
      this.$emit("onHandleReRenderReferral");
      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss">
.referralPopup-custom {
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: 0px 0px 0px 2px #4c9aff, 0px 2px 2px 0px rgb(0 0 0 / 14%),
      0px 1px 5px 0px rgb(0 0 0 / 12%);
    height: 120px;
    max-height: 120px;
  }

  .title-center {
    justify-content: center;
  }

  .fixed-editor {
    height: 200px;
  }
  .fit-margin-bottom {
    margin: 0 0px 65px 0;
  }
  .fit-content {
    .v-application .pt-5 {
      padding-top: 0px !important;
    }
  }
}
.v-application .success--text {
  color: #4caf50 !important;
  caret-color: #4caf50 !important;
}
.service-information {
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
  .h-4 {
    height: 40px !important;
  }
  .lh-4 {
    line-height: 40px !important;
  }
  .row-title {
    width: 90px;
    display: inline-block;
    text-align: right;
  }
  .row-text {
    display: inline-block;
    text-align: left;
  }
  .fw {
    width: 100%;
    float: left;
  }
  .tc {
    text-align: center;
  }
  .tr {
    text-align: right;
  }
  .v-treeview-node__root {
    padding-right: 0px;
  }
  .success {
    color: yellow;
    background-color: #4caf50 !important;
  }
  .bold {
    text-transform: uppercase;
  }
  .container {
    max-width: 1360px !important;
  }
  .no-data-text {
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 5px;
  }
  .short-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .content-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 340px;
    display: inline-grid;
  }
  .v-treeview-node__root {
    padding-left: 0px;
    padding-right: 8px;
  }
  .v-treeview-node__level {
    width: 0;
  }
  .v-treeview-node__root {
    border: 1px solid rgba(0, 0, 0, 0.6);
    width: 100%;
    border-top: none;
  }
  .v-treeview-node__content {
    margin-left: 0px;
  }
  .v-treeview-node__label {
    border-right: 1px solid rgba(0, 0, 0, 0.6);
  }
  .row-text {
    display: inline-block;
    text-align: left;
  }

  .v-treeview-node__label {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 25px;
  }
}

.fit-button {
  display: flex;
  justify-content: flex-end;
}
</style>